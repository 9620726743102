export default {
  public: [
    {
      path: '',
      name: 'login',
      component: () => import('@/modules/siteAdmin/views/login.vue'),
    },
    // 忘記密碼step1, 發重設密碼信件
    {
      path: '/send-reset-password-email',
      name: 'send-reset-password-email',
      component: () => import('@/modules/siteAdmin/views/resetPassword/sendResetPasswordEmail.vue'),
    },
    // 忘記密碼step2, 透過驗證碼登入
    {
      path: '/login-via-otp',
      name: 'login-via-otp',
      component: () => import('@/modules/siteAdmin/views/resetPassword/loginViaOtp.vue'),
    },
  ],
  admin: [
    // 忘記密碼step3, 重設密碼
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/siteAdmin/views/resetPassword/resetPassword.vue'),
    },

    {
      path: '/',
      name: 'admin.entry',
      component: () => import('@/modules/siteAdmin/views/adminEntry.vue'),
    },

    {
      path: 'create-admin-session',
      name: 'admin.create-admin-session',
      component: () => import('@/modules/siteAdmin/views/createAdminSession.vue'),
    },

    // 管理員個人資料設定
    {
      path: 'site-admin/profile',
      name: 'site-admin-profile',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/siteAdmin/views/siteAdminProfileForm.vue'),
    },

    // 管理員列表
    {
      path: 'site-admin',
      name: 'site-admin',
      component: () => import('@/modules/siteAdmin/views/siteAdminList.vue'),
    },

    // 管理員表單
    {
      path: 'site-admin/create',
      name: 'site-admin-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/siteAdmin/views/siteAdminForm.vue'),
    },
    {
      path: 'site-admin/update/:target',
      name: 'site-admin-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/siteAdmin/views/siteAdminForm.vue'),
    },

    // 管理員群組列表
    {
      path: 'site-admin-group',
      name: 'site-admin-group',
      component: () => import('@/modules/siteAdmin/views/siteAdminGroupList.vue'),
    },

    // 管理員群組表單
    {
      path: 'site-admin-group/create',
      name: 'site-admin-group-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/siteAdmin/views/siteAdminGroupForm.vue'),
    },
    {
      path: 'site-admin-group/update/:target',
      name: 'site-admin-group-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/siteAdmin/views/siteAdminGroupForm.vue'),
    },

    // 管理員系統紀錄列表
    {
      path: 'site-admin-log',
      name: 'site-admin-log',
      component: () => import('@/modules/siteAdmin/views/siteAdminLogList.vue'),
    },

    // 管理員系統紀錄表單
    {
      path: 'site-admin-log/create',
      name: 'site-admin-log-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/siteAdmin/views/siteAdminLogForm.vue'),
    },
    {
      path: 'site-admin-log/update/:target',
      name: 'site-admin-log-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/siteAdmin/views/siteAdminLogForm.vue'),
    },
  ],
}
